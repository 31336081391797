import React, {useMemo} from 'react';
import appsImage from '../../../assets/images/banner/apps-image.png'
import bannerShape2 from '../../../assets/images/banner/banner-shape2.png'
import bannerShape3 from '../../../assets/images/banner/banner-shape3.png'
import bannerShape4 from '../../../assets/images/banner/banner-shape4.png'
const Home6Banner = () => {
    const production = useMemo(()=>window.location.hostname === 'smsgpt.co',[]);
    return (
        <>
            <section className="sc-banner-section-area6 sc-pt-125 sc-sm-pt-150">
                <div className="container-fluid p-0">
                    <div className="row sc-banner-item d-flex align-items-center">
                        <div className="col-lg-6">
                            <div className="banner-header-content sc-mb-100 sc-md-mb-45 p-z-idex" data-sal="slide-up" data-sal-duration="1000" data-sal-delay="100">
                                <h1 className="banner-title p-z-idex sc-mb-25 sc-sm-mb-15 white-color">What if ChatGPT had a <span className="yellowColor">phone number?</span></h1>
                                <div className="banner-sub-title sc-mb-40 sc-sm-mb-25 white-dark-color">
                                     The same conversational AI chat bot, now with the ability to interact via SMS messaging.<br/>
                                    Whether you need help with a homework question, want to chat with a virtual friend, or need personalized recommendations, smsGPT has got you covered. Try it out today and experience the power of AI at your fingertips.
                                </div>
                                <div className="banner-btn-area d-flex align-items-center">
                                    
                                    <a className="sc-yellow-transparent2" href={production ? "https://buy.stripe.com/8wMcPlcxAcfygMweUW" : "https://buy.stripe.com/test_8wMcPlcxAcfygMweUW"} target="_blank" rel="noreferrer">
                                        <span><i className="ri-star-fill"></i> Lite</span>
                                    </a>
                                    <a className="sc-yellow-btn sc-yellow-btm sc-ml-15 sc-mr-15" href={production ? "https://buy.stripe.com/8wMbLh2X06Vebsc4gg" : "https://buy.stripe.com/test_dR601Z7aQ9MLbtKeUU"} target="_blank" rel="noreferrer">
                                        <span><i className="ri-medal-fill"></i> Plus</span>
                                    </a>
                                    <a className="sc-yellow-transparent2" href={production ? "https://buy.stripe.com/7sI4iPaps1AU0NydQR" : "https://buy.stripe.com/test_4gweWTgLq3ondBS7st"} target="_blank" rel="noreferrer">
                                        <span><i className="ri-trophy-fill"></i> Pro</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="sc-apps-image p-z-idex sc-pl-135">
                                <img src={appsImage} alt="Images" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sc-banner-style-two">
                    <img className="sc-shape-style-one" src={bannerShape2} alt="Banner" />
                    <img className="sc-shape-style-two" src={bannerShape3} alt="Banner" />
                    <img className="sc-shape-style-three" src={bannerShape4} alt="Banner" />
                </div>
            </section>
        </>
    );
};

export default Home6Banner;
