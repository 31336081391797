import React from 'react';
import { Fade } from 'react-reveal';
import faqApps from '../../../assets/images/feature/faq-apps.png'

const Home6Faq = () => {
    return (
        <>
            <section className="sc-crypto-faq-style sc-pt-160 sc-md-pt-80 sc-pb-140 sc-md-pb-60" id="faq">
                <div className="container" id="pricing">
                    <div className="row">
                        <div className="col-lg-6">
                            <Fade bottom>
                                <div className="sc-faq-images shake-y sc-pr-50 sc-md-pr-0 sc-md-mb-50">
                                    <img src={faqApps} alt="Images" />
                                </div>
                            </Fade>
                        </div>
                        <div className="col-lg-6">
                            <div className="sc-faq-pages-area sc-pl-10">
                                <div className="sc-heading-area sc-mb-25">
                                    <h2 className="heading-title white-color">Frequently Asked<span className="yellowColor"> Questions</span></h2>
                                </div>
                                <div className="accordion sc-faq-style" id="accordionExample">
                                    <p className="des white-dark-color">
                                        We've gathered our most popular questions
                                    </p>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingPrices">
                                            <button
                                               className="accordion-button"
                                               type="button"
                                               data-bs-toggle="collapse"
                                               data-bs-target="#collapsePrices"
                                               aria-expanded="true"
                                               aria-controls="collapsePrices"
                                            >
                                                What are the Prices?
                                            </button>
                                        </h2>
                                        <div id="collapsePrices" className="accordion-collapse collapse show" aria-labelledby="headingPrices" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <strong>You can text the AI for free, however, it is rate-limited. We also offer a Lite Plan ($5) for 100 monthly messages, along with a Plus Plan ($10) and Pro Plan ($15), both offering unlimited access.</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne"
                                                aria-expanded="false"
                                                aria-controls="collapseOne"
                                            >
                                                Is this really ChatGPT?
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <strong
                                                >Yes, we use the same language model powered by OpenAI's ChatGPT.
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="collapseTwo"
                                            >
                                                Do you offer access to GPT-4?
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <strong
                                                >Yes, we offer access to beta AI models such as GPT-4, via our Pro Plan.</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseThree"
                                                aria-expanded="false"
                                                aria-controls="collapseThree"
                                            >
                                                Do you store my chat logs?
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <strong
                                                >The AI has a short-term memory (slightly larger for Pro Plan), however we do not store the conversations.</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingFour">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseFour"
                                                aria-expanded="false"
                                                aria-controls="collapseFour"
                                            >
                                                How do I Upgrade or Cancel?
                                            </button>
                                        </h2>
                                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <strong
                                                >You can easily cancel or adjust your plan, billing settings, and account details via logging in above.</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home6Faq;
