import React from 'react';
const PrivacyDetails = () => {
    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        Collection of Personal Information: We collect personal information, such as name and email address, from users who sign up for our service or contact us for support. We may also collect certain data, such as user IP addresses and browser types, to analyze user behavior and improve our service.
                        <br/>
                        <br/>
                        Use of Personal Information: We use personal information to provide customer support, communicate with users about our service, and improve our service. We will never sell or share personal information with third parties without user consent, except as required by law.
                        <br/>
                        <br/>
                        Security of Personal Information: We take reasonable measures to protect personal information from unauthorized access, disclosure, or destruction. However, no data transmission over the internet or electronic storage is completely secure, and we cannot guarantee the absolute security of personal information.
                        <br/>
                        <br/>
                        Cookies: We may use cookies to enhance user experience and analyze user behavior. Users can control the use of cookies through their browser settings.
                         <br/>
                         <br/>
                        Changes to Privacy Policy: We may update our privacy policy from time to time. Users will be notified of any material changes to the policy and given the opportunity to review and accept the new policy before it takes effect.
                    </div>
                </div>
            </section>
        </>
    );
};

export default PrivacyDetails;
