import React from 'react';
import Header from '../../Shared/Header';
import Home6Banner from './Home6Banner';
import Home6Service from './Home6Service';
import Home6Faq from '../HomeFive/Home6Faq';
import Footer3 from "../../Shared/Footer3";

const HomeSix = () => {
    return (
        <>
            <Header
                parentMenu='home'
                headerClass='sc-header-section sc-header-section6'
                signUpBtn='sc-yellow-btn-two'
            />
            <Home6Banner />
            <Home6Service />
            <Home6Faq />
           <Footer3 />
        </>
    );
};

export default HomeSix;
