import React from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import ThanksDetails from './ThanksDetails';
import Footer3 from '../../Shared/Footer3';

const Thanks = () => {
    return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Thank You' empty={true} />
            <ThanksDetails />
            <Footer3 />
        </>
    );
};

export default Thanks;
