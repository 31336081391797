import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const BreadCums = (props) => {
    const { pageTitle, empty } = props;
    return (
        <>
            <div className={empty ? "sc-breadcrumbs-mini" : "sc-breadcrumbs"}>
                <div className="container">
                    <div className="breadcrumbs-text white-color">
                        <h1 className="page-title sc-sm-pt-20">{!empty ? pageTitle : ''}</h1>
                        <ul className={" d-none"}>
                            <li><Link className="active" to="/#">Home</Link></li>
                            <li>{pageTitle ? pageTitle : ""}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BreadCums;
