import React from 'react';
import { Fade } from 'react-reveal';
import featureImg7 from '../../../assets/images/feature/faq-apps.png'
import {Link} from "react-router-dom";

const ThanksDetails = () => {
    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <Fade left>
                            <div className="col-lg-6">
                                <div className="sc-feature-image pulse sc-md-mb-50">
                                    <img className="feature_img" src={featureImg7} alt="Feature" />
                                   {/* <div className="image-bg-circle"></div>*/}
                                </div>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-feature-item">
                                    <div className="sc-heading-area sc-mb-20">
                                        <span className="sc-sub-title primary-color">Thank You!</span>
                                        <h2 className="heading-title sc-mb-25">We appreciate your support.</h2>
                                        <div className="description sc-mb-25 d-none">
                                            Your subscription changes should take effect within a few moments.
                                        </div>
                                    </div>
                                    <div className="feature_check">
                                        <ul>
                                            <li><i className="ri-checkbox-circle-fill"></i>Your subscription should take effect within a few moments.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>An Invoice has been sent to your Email.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Login to change your plan at any time.</li>
                                        </ul>
    
                                        <div className="sc-primary-btn sc-md-pt-40 sc-mt-40">
                                            <a href="sms:+18554780870">
                                                <button className="primary-btn2"><span>Start Texting</span></button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ThanksDetails;
